.login {
  .card {
    &::after,
    &:before {
      content: "";
      background-image: url(../../../../public/assets/images/login-bg.png);
      background-size: contain;
      height: 10.625rem;
      height: 14.625rem;
      position: absolute;
      width: 10.625rem;
      width: 14.625rem;
      z-index: -1;
    }

    &::after {
      left: -7.32rem;
      top: -7.32rem;
    }

    &:before {
      bottom: -7.32rem;
      right: -7.32rem;
    }
  }
}
.form-check-label,
p {
  a {
    font-size: 14px !important; 
  }
}