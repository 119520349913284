.graph-chart {
  .card {
    .card-body {
      .highcharts-title {
        font-size: 0.75rem !important;
        font-weight: 400 !important;
        margin-bottom: 10px !important;
      }

      .highcharts-subtitle {
        font-size: 1.25rem !important;
        font-weight: 600 !important;
        fill: #111 !important;
      }
    }
  }
}

.date-button {

  .btn,
  .form-check {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.175);
  }

  .btn {
    text-transform: capitalize;
    color: #000;

    &:hover {
      background-color: #fff;
      border: 1px solid rgba(0, 0, 0, 0.175);
    }

    &:active {
      border-color: rgba(0, 0, 0, 0.175) !important;
    }
  }

  .form-check {
    padding: 8.5px 1.25rem;
    padding-left: 35px;
    border-radius: 5px;

    label {
      font-size: 0.9375rem;
      font-weight: 500;
    }
  }
}


.date-form {
  position: relative;

  .calendra-wrapper {
    position: absolute;
    z-index: 1;

    .rdrDateRangePickerWrapper {

      .rdrDefinedRangesWrapper {
        width: 11rem;
        padding: 15px 0;
        background: transparent;

        .rdrInputRanges {
          display: none;
        }

        .rdrStaticRange {
          border: 0;

          &.rdrStaticRangeSelected {
            color: #2003ff !important;
          }
        }
      }

      .rdrCalendarWrapper {
        max-width: 32.25rem;
        padding-top: 1rem;
        padding-right: 15px;
        padding-left: 15px;
        background: transparent;

        .rdrMonthAndYearPickers {
          display: none;
        }

        .rdrMonthAndYearWrapper {
          height: auto;
        }

        .rdrMonths {
          margin-top: -30px;

          .rdrDays {
            .rdrDay {
              height: 2.5em;

              .rdrStartEdge.rdrEndEdge,
              .rdrInRange,
              .rdrStartEdge,
              .rdrEndEdge {
                color: #2003ff !important;
              }

              &.rdrDayToday {
                .rdrDayNumber {
                  span {
                    font-weight: 600;

                    &:after {
                      display: none;
                    }
                  }
                }
              }

              &.rdrDayHovered {
                span {
                  border-color: #2003ff;
                }
              }
            }
          }

          .rdrMonthName {
            text-align: center;
          }
        }

        .rdrDateDisplayWrapper {
          background-color: #fff;

          .rdrDateDisplayItem {
            box-shadow: none;
            border: 1px solid #8a8a8a;

            &.rdrDateDisplayItemActive {
              border-color: #2003ff;
            }
          }
        }
      }
    }
  }
}