.pdf-container {
  width: 446.3pt;
  margin: 0 auto;
  position: relative;
  background-color: white;
  font-size: 7.5pt;
  padding: 7.5pt;

  .break-spaces {
    white-space: break-spaces;
  }

  table {
    width: 430.3pt;
    margin-bottom: 0;
    border: none;
    border-spacing: 0pt;
    border-collapse: collapse;

    p {
      margin: 0;
    }

    tr {
      border: none;
      border-spacing: 0pt;

      th,
      td {
        font-size: 10px;
        padding: 5px 0.5rem;

        .font-bold {
          font-weight: 500;
        }
      }

      td {
        h6 {
          font-size: 14px;
          margin-bottom: 0;
          letter-spacing: 1px;
          text-transform: uppercase;
        }

        h2 {
          margin-bottom: 0;
          line-height: 25px;
        }

        &.sub-total {
          border: 0;
          text-align: end;
          padding-bottom: 0;

          p {
            span {
              min-width: 100px;
              display: inline-block;

              &.paid-amount {
                font-size: 8px;
              }
            }
          }
        }
      }
    }

    &.border-table {
      tr {
        border-top: 0.2px solid #ccc !important;

        &.none-border {
          border-top: 0 !important;
          border-bottom: 0.2px solid #ccc !important;

          td {
            padding-top: 0;
          }
        }
      }
    }
  }

  ul {
    line-height: 11px;
  }

}