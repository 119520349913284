$primary: #2003ff;
$secondary: #595561;
$success: #008000;
$info: #00b5b4;
$warning: #000000;
$danger: #fa3434;
$light: #e9ecef;
$dark: #000;
$dark-opacity-068: rgba(51, 48, 60, 0.68);
$body: #f6f6f7;
$border-color: rgba(0, 0, 0, 0.25);

@import "bootstrap/scss/bootstrap.scss";
@import "./button-loading.scss";
@import "./form-control.scss";
@import "./loading.scss";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");

body {
  background: #f6f6f7;
  font-size: 14px;
  font-weight: 400;
  color: #000;
  font-family: "Poppins";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  color: #000;
}

h2 {
  // 28px
  font-size: 1.75rem;
  font-weight: 400;
  line-height: 1.938rem;
  color: #000;
}

h3 {
  // 22px
  font-size: 1.375rem;
  font-weight: 600;
  line-height: 1.312rem;
  letter-spacing: 0em;
}

h4 {
  // 18px
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.125rem;
}

a {
  // 16px
  font-size: 1rem;
}

.break-spaces {
  white-space: break-spaces;
}

.Toastify__close-button--light {
  color: white;
  opacity: 100% !important;
}

.main-footer {
  border-top: 1px solid $border-color;
  background-color: #f6f6f7;
  position: sticky;
  bottom: 0;
  z-index: 9;

  ul {
    li {
      .active {
        color: $primary;
      }
    }
  }
}

// Image

.drag-drop {
  label {
    color: rgba(51, 48, 60, 0.87);
    font-weight: 300;
    line-height: 0.9375rem;
  }

  .dropzone {
    padding: 10px;
    border: 1px blue dashed;
    border-radius: 0.4rem;
    // margin: auto;

    p {
      font-size: 12px;
    }
  }
}

.MyDropzone {
  .product-drag-drop {
    cursor: pointer;
  }

  .MyDropzone-2 {
    .product-drag-drop {
      height: 108px;
    }
  }
}

.product-drag-drop button.btn-close {
  position: absolute;
  margin-left: -18px;
  top: 3px;
  width: 2px;
}

.input-group-text {
  width: fit-content;
}

// Other

.btn {
  align-items: center;
  border-radius: 5px;
  display: flex;
  font-size: 0.9375rem;
  font-weight: 500;
  justify-content: center;
  letter-spacing: 0.0268rem;
  line-height: 1.125rem;
  padding: 0.625rem 1.25rem;
  text-transform: uppercase;
  width: fit-content;

  img,
  svg {
    margin-right: 0.5rem;
  }

  &:focus-visible {
    box-shadow: none;
  }

  .btn-text {
    display: flex;
    align-items: center;
  }

  &.btn-link {
    color: $primary;
    text-decoration: none;
    text-transform: none;
    font-weight: 400;
  }

  &.btn-primary {
    white-space: nowrap;
    background: $primary;
    color: #ffffff;

    img {
      margin-left: 8px;
    }

    &:hover {
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
        $primary;
    }
  }

  // &.btn-white {
  //   border: 1px solid #dee2e6;
  //   color: #848B9C;
  //   white-space: nowrap;

  //   img {
  //     margin-right: 8px;
  //   }
  // }
}

.show-password {
  right: 0;
  position: absolute;
  top: 42px;
}

.avtar {
  height: 36px;
  width: 36px;
  // padding-top: 5px;
  line-height: 36px;
  text-align: center;
}

.pos-justify {
  display: flex;

  &.pos-start {
    justify-content: start;
  }

  &.pos-center {
    justify-content: center;
  }

  &.pos-between {
    justify-content: space-between;
  }

  &.pos-end {
    justify-content: end;
  }
}

.pos-align {
  display: flex;

  &.pos-start {
    align-items: start;
  }

  &.pos-center {
    align-items: center;
  }
}

// .mb-3 {
//   margin-bottom: 1.25rem !important;
// }

.mb-md-0 {
  margin-bottom: 0 !important;
}

.react-datepicker-wrapper {
  display: block;
  padding: 0;
  border: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  // width: 100%;
}

.campaigndatepicker {
  position: relative;

  .react-datepicker__input-container input::placeholder {
    font-weight: 300 !important;
    color: rgba(51, 48, 60, 0.4) !important;
    opacity: 0.4;
  }
}

.react-datepicker-wrapper {
  display: block !important;

  input {
    width: 100%;
    padding: 0.438rem 0.6rem;
    font-size: 0.9375rem;
    border: 1px solid;
    border-color: $border-color;
    border-radius: 5px;
    line-height: 1.5rem;
    font-weight: 200;
    text-shadow: 0 0 rgba(51, 48, 60, 0.87);
    color: rgba(51, 48, 60, 0.87);
    outline: 0;
    background: url(../../../public/assets/images/calendar-event.svg);
    background-repeat: no-repeat;
    background-position: center right 0.625rem;

    &:focus {
      box-shadow: 0 4px 4px rgba(0, 0, 0, 0.08);
      border: 0.0625rem solid $primary;
      border-color: $primary;
    }

    &.invalid {
      border-color: $danger;
    }
  }

  .react-datepicker__day {
    &--selected {
      background-color: $primary;
    }

    &:hover {
      background: rgba(115, 103, 240, 0.08);
      color: $primary;
    }
  }
}

.pagination-wrap {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 1.25rem 1.25rem 1.25rem 1.25rem;

  .pagination {
    margin-bottom: 0;

    .disabled {
      opacity: 0.2;
    }

    .page-item {
      margin-right: 0.5rem;

      .page-link {
        border: 0;
        color: $dark-opacity-068;
        height: 1.875rem;
        width: 1.875rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: $border-radius;
        font-weight: 500;
        font-size: 0.75rem;
        line-height: 0.875rem;

        &:hover {
          background-color: $primary;
          color: $white;
        }

        &:focus {
          box-shadow: none;
        }
      }

      &.active {
        .page-link {
          color: $white;
        }
      }

      .previous-arrow,
      .next-arrow {
        font-size: 0 !important;

        &::before {
          content: "";
          background: url(../../../public/assets//images/right-arrow-icon.svg) no-repeat;
          background-size: contain;
          width: 0.438rem;
          height: 0.625rem;
          display: inline-block;
          vertical-align: middle;
          transform: rotate(180deg);
        }

        &:hover {
          &::before {
            background: url(../../../public/assets//images/right-arrow-white-icn.svg) no-repeat;
            background-size: contain;
          }
        }
      }

      .next-arrow {
        &::before {
          transform: inherit;
        }
      }
    }
  }
}

.card {
  .card-header {
    .loader {
      position: absolute;
      top: -28px;
      right: 0;
      left: 0;
      margin: auto;
    }
  }
}

.table-responsive {
  &::-webkit-scrollbar {
    visibility: hidden;
  }
}

table {
  position: relative;
  z-index: 1;
  table-layout: fixed;

  tr {

    th,
    td {
      &:first-child {
        padding-left: 20px;
      }

      &:last-child {
        padding-right: 20px;
        text-align: end;
      }
    }
  }

  thead {
    position: sticky;
    top: 0;
    z-index: 2;

    tr {
      height: 3.625rem;
      vertical-align: middle;

      th {
        text-transform: uppercase;
        white-space: nowrap;
        // width: 15rem;

        a {

          img,
          svg {
            margin: 0 0 0 0.938rem;
            width: 0.75rem;
          }
        }

        button {

          img,
          svg {
            width: 0.75rem;
            margin: 0 0 0 0.938rem !important;
          }

          &.filter-add {

            img,
            svg {
              margin: 0 0 0 1.25rem !important;
            }
          }
        }

        label {

          img,
          svg {
            margin: 0 0 0 1.25rem;
            width: 0.75rem;
          }
        }

        .btn {
          &-transparent {
            &.dropdown-toggle {
              &.filter-add {
                img {
                  margin-right: 0;
                }
              }
            }
          }
        }

        .btn.btn {
          font-size: 0.875rem;
          font-weight: bold;
          position: relative;

          &::after {
            display: none;
          }

          &.dropdown-toggle {
            &.filter-add {
              &::before {
                content: "";
                position: absolute;
                right: 1.125rem;
                background: red;
                width: 8px;
                height: 8px;
                border-radius: 50%;
              }
            }
          }
        }

        .dropdown {
          position: absolute;

          .dropdown-menu {
            border: 0;
            border-radius: 5px;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            padding: 0.5rem;
            width: 12.188rem;
            margin-top: 15px !important;

            .form-group {
              margin: 0 !important;

              .react-dropdown {
                .react-select__control {
                  .react-select__value-container {
                    padding: 0.1875rem 0 0.1875rem 0.5rem;

                    .react-selectinput {
                      font-size: 0.9375rem !important;
                    }
                  }

                  .react-select__indicator {
                    &:last-child {
                      background: url(../../../public/assets/images/search.svg);
                      background-position: center right 0.5rem;
                      background-repeat: no-repeat;
                      background-size: 1.0625rem;
                      color: rgba(51, 48, 60, 0.6);

                      svg {
                        visibility: hidden;
                      }
                    }
                  }
                }

                .react-select__menu-list {
                  .react-select__option {
                    border-radius: 5px;
                    color: rgba(51, 48, 60, 0.87);
                    font-size: 0.9375rem;
                    font-weight: 400;
                    letter-spacing: 0.01em;
                    line-height: 1.125rem;
                    margin-bottom: 0.375rem;
                    padding: 0.6875rem 1.25rem;
                    text-transform: capitalize;
                    white-space: break-spaces;

                    &:last-child {
                      margin-bottom: 0;
                    }
                  }

                  .react-select__menu-notice--no-options,
                  .react-select__menu-notice--loading {
                    font-weight: 400;
                    font-size: 0.9375rem;
                    line-height: 1.125rem;
                    letter-spacing: 0.01em;
                    text-transform: initial;
                  }

                  &::-webkit-scrollbar {
                    display: none;
                  }
                }

                .react-select__menu {
                  box-shadow: none;
                  padding: 0;
                  position: inherit;
                  z-index: 0;
                }
              }
            }
          }
        }

        .arrow-up-down {
          transform: rotate(90deg);
        }

        &:first-child {
          border-top-left-radius: 0.5rem;
        }

        &:last-child {
          padding-right: 20px;
          border-top-right-radius: 0.5rem;
        }

        &.sku {
          width: 100px;
        }

        &.price {
          width: 150px;
        }

        &.stock {
          width: 120px;

          .react-select__option {
            text-transform: none !important;
          }
        }

        &.date {
          width: 180px;
        }

        &.action {
          width: 95px;
        }

        &.status {
          width: 120px;
        }

        &.total {
          width: 160px;
        }

        &.order-id {
          width: 120px;
        }

        &.quantity {
          width: 100px;
        }

        &.type {
          width: 130px;
        }

        &.mobile {
          width: 115px;
        }

        &.role {
          width: 140px;
        }

        &.duration {
          width: 100px;
        }

        &.priority {
          width: 115px;
        }

        &.tax {
          width: 110px;
        }
      }
    }
  }

  tbody {
    height: 3.625rem;

    tr {
      height: 3.625rem;
      vertical-align: middle;

      td {
        // white-space: nowrap;
        // word-break: break-word;

        .form-check-input {
          width: 18px;
          height: 18px;
        }

        img,
        svg {
          height: 1.25rem;
          object-fit: scale-down;
          width: 1.25rem;
        }

        ul {
          &.list-inline {
            margin-bottom: 0;

            img,
            svg {
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }

        small {
          margin-top: -5px;
        }

        p {
          &.summary {
            margin-bottom: 10px;
            font-weight: 600;

            span {
              font-weight: 400;
            }
          }
        }

        &.no-entry {
          border: 0 !important;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.08) !important;
          height: 13.5625rem;
          position: relative;
          top: 7px;

          aside {
            display: inline-grid;
            height: -webkit-fill-available;
            justify-content: center;

            svg {
              margin: auto auto 0.75rem;
              width: 25px;
              height: 25px;
            }
          }
        }

        a {
          &.btn {
            text-align: start;
          }
        }

        &:last-child {
          .loader {
            position: absolute;
            left: 13px;
            right: 0;
            margin: auto;
            top: 0;
          }
        }
      }

      &:last-child {
        td {
          border-bottom: 0;

          &:first-child {
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-bottom-right-radius: 0.5rem;
          }
        }
      }
    }
  }

  &.order-listing {
    thead {
      tr {
        th {
          // &:first-child {
          //   width: fit-content;
          // }

          &:last-child {
            // width: 150px;
            text-align: end;
          }
        }
      }
    }
  }

  &.order-payments {
    thead {
      position: initial;
    }

    tr {
      th {
        text-transform: initial;
      }

      td,
      th {
        font-size: 12px;
        padding-top: 3px;
        padding-bottom: 3px;
        line-height: normal;

        &:first-child,
        &:last-child {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }
  }

  &:not(.sales-table) {
    thead {
      tr {
        th {
          &:first-child {
            width: 55px;
          }
        }
      }
    }
  }
}

header {
  .back-button {
    width: 30px;

    svg {
      margin: 0 !important;
      width: 20px;
      height: 20px;
    }
  }
}

.tabs {
  align-items: center;
  display: flex;
  gap: 0.3125rem;
  margin-bottom: 1.25rem;
  justify-content: space-evenly;

  @media only screen and (max-width: 1369px) {
    justify-content: space-between;
  }

  .nav-item {
    .btn-link {
      color: rgba(51, 48, 60, 0.6);
      font-size: 1rem;
      font-weight: 300;
      line-height: 1.1875rem;
      min-width: 13.75rem;
      text-transform: uppercase;

      @media only screen and (max-width: 1369px) {
        min-width: 11rem;
      }

      @media only screen and (max-width: 1104px) {
        min-width: 8rem;
      }

      @media only screen and (max-width: 834px) {
        min-width: 7rem;
        font-size: 0.875rem;
      }

      &.active {
        color: #fff;
        background-color: #2003ff;
      }
    }
  }
}

.sales-table {
  max-height: 72vh;

  .quantity {
    width: 100px;

    input {
      box-shadow: none;
      margin: 0;
      border: 0;
    }

    button {
      padding: 8px 4px;
      border: 0;

      svg {
        font-size: 14px;
      }
    }
  }
}

.payable-amount {
  li {
    h6 {
      margin-bottom: 0;
      font-weight: 500;

      button {
        font-weight: 500 !important;
      }
    }
  }

  .pay-input {
    margin-bottom: 0 !important;
    width: 150px;
  }
}

.payment-methods {
  ul {
    scrollbar-width: thin;
    overflow-x: auto;
    margin-bottom: 0;

    &::-webkit-scrollbar {
      width: 6px;
      height: 5px;
      background-color: #f5f5f5;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #ada0a0;
      width: 6px;
    }

    li {
      button {
        padding: 5px 8px;
        font-size: 12px;
      }
    }
  }

  h6 {
    .btn {
      svg {
        padding: 1px 3px;
        margin: 0;

        &:hover {
          background: #ffbdad;
          color: #de350b;
          border-radius: 50%;
        }
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
.quantity input::-webkit-outer-spin-button,
.quantity input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  text-align: center;
  padding: 0;
}

/* Firefox */
.quantity input[type="number"] {
  -moz-appearance: textfield;
  text-align: center;
  padding: 0;
  // font-size: 18px;
}

.customer-list {
  .iconSearch {
    .customer-text-search {
      top: 0;
      bottom: 0;
      left: 8px;
    }

    .react-select__indicators {
      .react-select__dropdown-indicator {
        display: none;
      }
    }
  }

  .react-select__control {
    padding: 0 10px;

    .avtar-wrap {
      color: rgba(51, 48, 60, 0.87) !important;
      font-weight: 400;
    }
  }

  .react-select__menu {
    box-shadow: none !important;
    margin-top: 0;
    padding: 5px 0 !important;

    .react-select__option--is-selected {
      background-color: #deebff !important;

      .avtar-wrap {
        color: rgba(51, 48, 60, 0.87) !important;
      }
    }
  }

  ul {
    a.btn {
      padding: 0.625rem;
    }

    li {
      margin-bottom: 0.375rem;
      border: 0;
      border-radius: 5px;

      &:hover,
      &:focus {
        background-color: rgb(222, 235, 255);
        color: #000;
      }

      &.active {
        background-color: rgb(222, 235, 255);
        color: #000;
      }

      button {
        aside {
          padding: 0 10px !important;
        }
      }
    }
  }

  form {
    .body {
      height: calc(100vh - 343px);
      overflow: auto;

      hr {
        margin: 0.5rem 0;
      }
    }

    .payment-mode {
      padding-top: 14px;
      position: sticky;
      bottom: 0%;
      z-index: 3;
      background-color: #fff;
      padding-bottom: 10px;

      li:hover {
        background-color: transparent;
      }
    }
  }
}

.highcharts-credits {
  display: none !important;
}

.iconSearch .react-select__menu-list {
  max-height: calc(100vh - 176px) !important;
}

.breadcrumb-table {
  margin-top: 16px;

  .breadcrumb-item {
    a {
      text-decoration: none;
      font-size: 14px;
    }

    &:not(:first-child) {
      &::before {
        content: ">";
      }
    }
  }
}

.fa-right-from-bracket {
  color: $danger;
  margin-right: 0;
}

.offcanvas {
  .offcanvas-body {
    table {
      thead {
        position: sticky;
        top: 0px;
        background-color: #f2f2f2;
      }
    }
  }
}

.modal {
  &.fade {
    &.show {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
}

form.form-loading.btn-loading {
  position: relative;

  &::after {
    position: absolute;
    right: 40px;
    left: inherit;
    top: 0px;
    z-index: 9;
  }
}

.customer-list {
  form.form-loading.btn-loading {
    &::after {
      top: 8px;
      bottom: auto;
    }
  }
}

form.form-loading.is-loading {
  &::before {
    backdrop-filter: blur(0px);
  }

  &::after {
    top: 20px;
    z-index: 9;
  }
}

.sales-list {
  .form-group {
    z-index: 0;
  }

  .summary-icons {
    position: absolute;
    bottom: 70px;
    left: 20px;

    ul {
      list-style: none;

      li {
        &:not(:last-child) {
          margin-bottom: 16px;
        }

        button {
          border-radius: 50%;
          width: 40px;
          height: 40px;
          line-height: 40px;
          z-index: 1;
          position: relative;
        }
      }
    }

    .modal {
      .modal-header {
        button {
          width: auto;
          height: auto;
          padding: 1rem;
          line-height: normal;
          border-radius: 0;
          border: 0;
          box-shadow: none;
        }
      }

      .modal-body {
        padding: 0;

        .date-header {
          padding: 8px 1rem;

          aside {
            h4 {
              font-size: 40px;
              margin-right: 8px;
            }
          }

          p {
            text-transform: uppercase;
            margin-left: auto;
          }
        }

        ul {
          li {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 25px 18px;
            margin-right: 0;
            margin-bottom: 0;

            &:not(:last-child) {
              border-bottom: 1px solid $border-color;
            }

            h6 {
              margin-bottom: 0;
            }

            &:last-child {
              background-color: #efeffd;
              border-bottom-left-radius: 0.5rem;
              border-bottom-right-radius: 0.5rem;
            }
          }
        }

        table {
          tbody {
            tr {
              &:last-child {
                td {
                  &.completed {
                    color: rgba(0, 0, 0, 0.5);
                    text-decoration: line-through;
                  }
                }
              }

              td {
                white-space: normal;
              }
            }
          }
        }
      }
    }
  }
}

.success-icon {
  position: relative;
  top: -2px;
  right: 0;
}

fieldset legend {
  font-size: 20px;
}

.Toastify {
  .Toastify__toast-container {
    .Toastify__toast--success {
      .Toastify__toast-icon {
        svg {
          fill: white;
        }
      }
    }

    .Toastify__toast--error {
      .Toastify__toast-icon {
        svg {
          fill: $danger;
        }
      }
    }

    .Toastify__progress-bar--success {
      background: #fff;
    }
  }
}

.header-loading {
  form {
    width: 250px;
  }

  .is-loading {
    &:after {
      width: 25px;
      height: 25px;
    }
  }
}

// Pagination Loader:-
.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
  width: 1em;
  height: 1em;
  animation-fill-mode: both;
  animation: bblFadInOut 1.8s infinite ease-in-out;
}

.loader {
  color: #2003ff;
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;
}

.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}

.loader:before {
  left: -3.5em;
  animation-delay: -0.32s;
}

.loader:after {
  left: 3.5em;
}

@keyframes bblFadInOut {

  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}

.active-filters {
  li {
    &:last-child {
      button {
        text-transform: none;
      }

      @media only screen and (max-width: 991px) {
        margin-bottom: 5px;
      }
    }
  }

  button {
    font-size: 12px;
    padding: 1px 5px;

    svg {
      padding: 0 2px;

      &:hover {
        background: #ffbdad;
        color: #de350b;
        border-radius: 50%;
      }
    }
  }
}

.order-loader {
  position: absolute;
  right: 70px;
  top: 10px;
  left: auto;
  margin: auto;
}

.new-inventory {
  .recently-added {
    .card-body {
      max-height: 386px;
      overflow: auto;
    }
  }

  table tbody {
    tr {
      td {
        img {
          height: 3.25rem;
          object-fit: scale-down;
          width: 4.25rem;
        }

        &:first-child {
          border-bottom-left-radius: 5px;
          border-top-left-radius: 5px;
        }

        &:last-child {
          border-bottom-right-radius: 5px;
          border-top-right-radius: 5px;
        }
      }
    }
  }
}

.searchHide {
  .react-select__control {
    display: none;
  }
}

.more-modal {
  position: relative;

  .modal-content {
    .modal-body {
      width: 895px;
      margin: auto;

      @media only screen and (max-width: 1024px) and (min-width: 375px) {
        width: 100%;
      }
    }

    .button-close {
      position: absolute;
      top: 30px;
      right: 50px;
    }
  }
}

.orders-list {
  ul {
    li {
      .active {
        color: $primary !important;
      }
    }
  }
}

.confirmed-card {
  .balance-due {
    strong {
      color: $danger;
    }
  }
}

.deta-table {
  overflow-x: visible;
}

.login-form-vh {
  @media screen and (max-width: 820px) and (min-width: 771px) {
    height: 73vh !important;
  }

  @media screen and (max-width: 770px) and (min-width: 760px) {
    height: 83vh !important;
  }

  .card {
    padding: 35px;

    @media screen and (max-width: 770px) and (min-width: 760px) {
      padding: 26px;
    }
  }
}

#form {
  position: relative;
  margin-top: 20px;
}

#form fieldset {
  background: white;
  border: 0 none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  padding-bottom: 20px;
  position: relative;
}

#form fieldset:not(:first-of-type) {
  display: none;
}

#form .previous-step,
.next-step {
  width: 100px;
  font-weight: bold;
  color: white;
  border: 0 none;
  border-radius: 0px;
  cursor: pointer;
  padding: 10px 5px;
  margin: 10px 5px 10px 0px;
  float: right;
}

.form,
.previous-step {
  background: #616161;
}

.form,
.next-step {
  background: $primary;
}

#form .previous-step:hover,
#form .previous-step:focus {
  background-color: #000000;
}

#form .next-step:hover,
#form .next-step:focus {
  background-color: $primary;
}

.text {
  color: $primary;
  font-weight: normal;
}

#progressbar {
  color: lightgrey;
  padding: 25px 0;
}

#progressbar .active {
  color: $primary;
}

#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 33.333%;
  float: left;
  position: relative;
  font-weight: 400;
}

#progressbar li:before {
  width: 16px;
  height: 16px;
  line-height: 45px;
  display: block;
  font-size: 20px;
  color: #ffffff;
  background: lightgray;
  border-radius: 50%;
  margin: 0 auto -38px auto;
  padding: 2px;
  content: "";
}

#progressbar li:after {
  content: "";
  width: 100%;
  height: 4px;
  background: lightgray;
  position: absolute;
  left: 0;
  top: 8px;
  z-index: -1;
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: $primary;
}

.product-list-items .quantity {
  width: 87px;
}

.product-list-items .btn-primary {
  padding: 5px 7px;
}

.customer-right-colum {
  height: calc(100vh - 359px);
  overflow: auto;

  @media only screen and (max-width: 1440px) {
    height: calc(100vh - 347px);

  }
}

.right-contnet-box .card .card-body {
  height: calc(100vh - 220px);
  overflow: auto;
}

.right-contnet-box .card-icon-box {
  flex-direction: column;
  align-items: flex-end;
  display: flex;

}

.genral-label a {
  font-weight: 300;
  font-size: 14px;
}

.genral-label label {
  font-weight: 300;
  font-size: 14px;
  color: rgba(51, 48, 60, 0.87);
}

.disabled-link {
  pointer-events: none;
}

.manage-stock-status {
  .form-check {
    &-inline {
      margin: {
        right: 10px;
        top: 5px;
        bottom: 5px;
      }

      label {
        font-size: 12px;
      }
    }
  }
}

.react-select__placeholder {
  white-space: nowrap;
}